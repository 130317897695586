.home-container {
    display: flex;
    flex-direction: column;
    /* height: 90vh; */
    min-height: 89vh;
}

.section {
    border-bottom: 1px dotted black; /* Add a dotted line between sections */
    padding: 10px; /* Add some padding around the content */
    text-align: center; /* Center the text horizontally */
}

.section h2 {
    margin: 10px; /* Remove the default top and bottom margin */
}

.sidebar {
    width: 200px;
    background-color: #f0f0f0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar a {
    text-decoration: none;
    color: #333;
}

.bottom-link {
    margin-top: auto;
}

.main-content {
    flex-grow: 1;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.top-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.card {
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    width: 30%;
}

.home-table-container {
    /* height: calc(90vh - 20%); */
    /* overflow-y: auto;  */
    flex-grow: 1; /* Allows this section to grow and occupy remaining space */
    display: flex;
    flex-direction: column;
}

.home-table-container h2 {
    text-align: center; /* Centers the Library title */
    margin: 10px 0; /* Adds space above and below the title */
}

.home-table {
    width: 100%;
    border-collapse: collapse;
    flex-grow: 1;
    overflow-y: auto; /* Scroll only within the table if the content overflows */
    border-collapse: collapse;
}

.home-table, th, td {
    border: 0.1px solid black;
}

.home-table th {
    padding: 10px;
    text-align: center;
}

.home-table td {
    padding: 10px;
}

.button-group {
    margin-top: 10px; /* Additional space above the buttons */
    display: flex;
    justify-content: center; /* Center buttons horizontally */
    gap: 10px; /* Space between buttons */
}

.button-group button {
    padding: 10px 20px; /* Padding inside buttons */
}

.button-group button {
    padding: 12px;
    background-color: #07849dff;
    color: white;
    border: 1px solid white;;
    border-radius: 10px;
    cursor: pointer;
    height: 50px;
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    margin: 20px;
    text-decoration: none;
}

.button-group button:hover {
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}

/* Add more styles as needed for the table */
.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.pagination-controls button {
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
}

.pagination-controls button.active {
    font-weight: bold;
    text-decoration: underline;
}

.share-button-container {
    display: flex;
    justify-content: center; /* Center the button */
    margin-bottom: 5px; /* Space between the table and the share button */
    margin-top: 0px;
}

.share-button-container button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    height: 40px;
    width: 100px;
    font-size: 20px;
}


.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    align-items: center;
    text-align: center;
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 20px;
    width: 80%;
    max-width: 500px;
    align-items: center;
    text-align: center;
}

.modal-content button {
    cursor: pointer;
    /* height: 40px;
    width: 200px;
     */
    font-weight: bold;
    font-size: 16px;
    color: white; 
    padding: 12px;
    margin-top: 10px; 
    background-color: #07849dff;
    border-radius: 10px;
    border: 1px solid white;
    text-decoration: none;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

