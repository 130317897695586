/* LoginPage.css */
.top-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f9fa;
    /* text-align: center; */
    padding: 0.5rem 0; 
    border-bottom: 1px solid #ddd;
  }

.top-banner .left-icons, .right-icons {
    display: flex;
    align-items: center;
}

.top-banner .center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; /* Center everything in the banner */
}
  
.top-banner .logo {
    max-width: 30px; /* Adjust as per your logo's dimensions */
    max-height: 30px; /* Adjust as per your logo's dimensions */
    margin-right: 0.5rem; /* Space between logo and name */
}

.top-banner h1 {
    margin: 0;
    color: black; /* Dark text color, can be changed */
    font-size: 1.5rem; /* Adjust as needed */
}

.dropdown-menu {
    display: none;
    top: 100%;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
.dropdown-menu .menu-item {
color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-menu .menu-item:hover {
    background-color: #f1f1f1;
}

.right-icons {
    position: relative; /* For absolute positioning of the dropdown */
}

/* Show the dropdown menu when the icon-menu is clicked */
.right-icons .icon-menu + .dropdown-menu {
    display: block;
}

.login-container {
    display: flex;
    flex-direction: column;
    height: 89vh;
    justify-content: space-between;
    background-color: #f8f9fa;
    /* max-width: 400px;
    margin: 2rem auto;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px; */
}
.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 50px;
    border: 2px solid #ddd; /* Bordered box */
    border-radius: 60px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    background-color: #ffffff; /* Optional: background color for the form */
}
.form-group {
    margin-bottom: 1rem;
}

.login-label {
    display: block;
    margin-bottom: 0.5rem;
}

.login-label-confirm-password {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 300px;
}

.login-button {
    padding: 12px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    height: 60px;
    width: 140px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px; 
    background-color: #07849dff;
    border: 1px solid white;
}

.login-button-forgot-password {
    padding: 12px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    height: 60px;
    width: 140px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px; 
    background-color: #07849dff;
    border: 1px solid white;
}

.login-button:hover {
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}
.login-button-forgot-password:hover {
    color: black;
    background-color: white;
    border: 1px solid black;
    cursor: pointer;
}
.login-header {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.form-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.form-links a {
    color: #007bff;
    text-decoration: none;
}

.form-links a:hover {
    text-decoration: underline;
}

.password-container {
    position: relative;
  }
  
.password-input {
    padding-right: 30px; /* Make room for the eye icon */
}

.password-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    padding-top: 40px;
    width: 20px;
    height: 20px;
}
  