.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  padding: 10px;
}

.flex-child {
  flex: 1;
  border: 2px solid black;
}  

.flex-child:first-child {
  margin-right: 20px;
}

.chart-container {
  display: flex;
}

.chart-container-left {
  display: flex;
  width: 75%;
  flex-direction: column;
  /* border: solid 1px black; */
  margin: 10px;
  margin-top: 0px;
}
.chart-container-left-talent {
  display: flex;
  width: 80%;
  flex-direction: column;
  /* border: solid 1px black; */
  margin: 10px;
  margin-top: 0px;
  margin-left: 160px;
}
.chart-container-left-talent-bizdev {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* border: solid 1px black; */
  margin: 10px;
  margin-top: 0px;
}

.chart-container-left-hc {
  display: flex;
  width: 75%;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
  margin-top: 0px;
}
.chart-container-left-hc-1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
  margin-top: 0px;
}
.chart-container-left-hc-report {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* border: solid 1px black; */
  margin: 10px;
  margin-top: 0px;
}
.chart-container-left-universe {
  display: flex;
  width: 15%;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
}
.chart-container-middle-universe {
  display: flex;
  width: 61%;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
}
.chart-container-left-child {
  border: solid 1px black;
}
.chart-container-right {
  display: flex;
  flex: 1;
  align-items:stretch;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
  margin-top: 0px;
}
.chart-container-right-hc {
  display: flex;
  flex: 1;
  align-items:stretch;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
  margin-top: 0px;
}
.chart-container-right-universe {
  display: flex;
  flex: 1;
  align-items:stretch;
  flex-direction: column;
  border: solid 1px black;
  margin: 10px;
}
.chart-container-right-child {
  padding-left: 2%;
}
.chart-container-right-child-hc {
  padding-left: 5%;
  border: solid 1px black;
}

.bordered-box {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-left: 10px;
  width: max-content;
  padding-right: 5px;
}
.bordered-box-talent {
  /* border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black; */
  margin-left: 160px;
  width: max-content;
  padding-right: 5px;
}

.dotted-line {
  border-top: 1px dotted #000;  /* You can adjust the color and size as needed */
  width: 100%;
  margin: 20px 0; /* Add some margin to space it out */
}

/* Style for buttons */
.button {
  font-weight: bold;
  font-size: 16px;
  color: white; 
  padding: 12px;
  margin-top: 10px; 
  background-color: #07849dff;
  border-radius: 10px;
  border: 1px solid white;
  text-decoration: none;
  /* transition: transform 0.1s ease; */
}

.button:hover {
  /* transform: scale(1.1); */
  /* background-color: darkslateblue; */
  color: black;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
}

.button.active {
  color: black;
  background-color: white;
  border: 1px solid black;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250, 249, 249, 0.689);
  z-index: 9999;
}

.textContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensure it appears on top of the modal overlay */
}

.textContainer .textHeader {
  font-weight: bold;
  font-size: 1.5em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.textContainer .textContent {
  font-size: 1em;
  margin-bottom: 15px;
}

.textContainer button {
  margin-top: 20px;
  padding: 10px 15px;
  border: none;
  background-color: #007BFF;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  /* This is just to space out the button from the text */
  margin-top: 15px;
}

.textContainer button:hover {
  background-color: #0056b3;
}

.edit-button {
  font-weight: bold;
  font-size: 16px;
  color: white; 
  padding: 12px;
  margin-top: 10px; 
  background-color: #07849dff;
  border-radius: 10px;
  border: 1px solid white;
  text-decoration: none;
  position: absolute;
  top: 100px;
  right: 100px;
}

.edit-button:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
}
